<template>
  <div class="footerView">
    <div class="wrap">
      <div class="nav">
        <router-link to="/search">会员库</router-link>
        <router-link to="/case">成功案例</router-link>
        <router-link to="/index">个人中心</router-link>
      </div>
      <div class="line">
        
      </div>
      <div class="Copyright">{{ site.copyright }} <a href="https://beian.miit.gov.cn/" target="_blank">备案号:{{ site.beian }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerView",
  props: ['site'],
};
</script>

<style scoped lang="scss">
.footerView {
  background: #fff;
  text-align: center;
  height: 180px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  .nav {
    a {
      display: inline-block;
      padding: 0 30px;
      border-right: 1px solid #eee;
    }
    a:last-child {
      border: none;
    }
  }
  .line {
    color: #666;
    margin: 16px 0;
    font-size: 14px;
  }
  .Copyright {
    color: #999;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .footerView {
    height: auto;
    padding: 16px 0;
  }
  .footerView .line {
    font-size: 12px;
    margin: 10px 0;
  }
  .footerView .Copyright {
    font-size: 12px;
  }
}
</style>
