<template>
  <div>
    <div class="NavBar">
      <div class="wrap">
        <div class="logo"><router-link to="/index"><img :src="site.logo" alt="" /></router-link></div>
        <div class="nav">
          <router-link to="/search">会员库</router-link>
          <router-link to="/case">成功案例</router-link>
          <router-link to="/index">个人中心</router-link>
        </div>
        <div class="login">
          <el-popover
            placement="bottom"
            width="140"
            trigger="hover"
            v-if="token"
          >
            <ul class="menu">
              <li>
                <router-link to="/">个人中心</router-link>
              </li>
              <li @click="logout">退出登录</li>
            </ul>
            <div slot="reference" class="user">
              <i class="el-icon-user-solid"></i>
            </div>
          </el-popover>
          <router-link v-else to="/login">登录</router-link>
        </div>
      </div>
    </div>
    <div class="seat"></div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      token: localStorage.getItem("token"),
    };
  },
  props: ['site'],
  watch: {
    $route(newVal) {
      if (newVal) {
        this.token = localStorage.getItem("token");
      }
    },
  },
  methods: {
    logout() {
      this.$router.push("/login");
      localStorage.removeItem("token");
    },
  },
};
</script>

<style scoped lang="scss">
.NavBar {
  height: 80px;
  background: #f43c8a;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 10000;
  .logo img {
    max-width: 88px;
  }
  .nav {
    a {
      display: inline-block;
      margin: 0 30px;
      position: relative;
      &::before {
        content: "";
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        bottom: -8px;
        height: 3px;
        background: #fff;
        position: absolute;
        border-radius: 10px;
        transition: all 0.3s;
      }
    }
    a:hover::before,
    .router-link-active::before {
      width: 100%;
    }
  }

  .user {
    width: 32px;
    height: 32px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #f43c8a;
    font-size: 20px;
    cursor: pointer;
  }
}
.menu {
  li {
    color: #333;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #f43c8a;
    }
  }
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seat {
  height: 80px;
}

@media screen and (max-width: 768px) {
  .NavBar,
  .seat {
    height: 50px;
  }
  .NavBar .logo img {
    max-width: 60px;
  }
  .NavBar .nav a {
    margin: 0 10px;
  }
  .NavBar .user {
    width: 28px;
    height: 28px;
    font-size: 14px;
  }
}
</style>
