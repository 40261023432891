import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const base_title = "牵手手"

const routes = [
  {
    path: "/",
    redirect: "index",
  },
  {
    path: "/index",
    name: "index",
    meta: { title: '会员中心' }, // 配置路由标题
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    meta: { title: '会员详情' }, // 配置路由标题
    component: () => import("@/views/detail.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: { title: '会员库' }, // 配置路由标题
    component: () => import("@/views/search.vue"),
  },
  {
    path: "/case",
    name: "case",
    meta: { title: '成功案例' }, // 配置路由标题
    component: () => import("@/views/case/index.vue"),
  },
  {
    path: "/caseDetail",
    name: "caseDetail",
    meta: { title: '案例详情' }, // 配置路由标题
    component: () => import("@/views/case/detail.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { title: '登录页面' }, // 配置路由标题
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/addVip",
    name: "addVip",
    meta: { title: '添加/编辑会员' }, // 配置路由标题
    component: () => import("@/views/addVip/index.vue"),
  },
];
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " - " + base_title // 设置页面标题
  }
  next()
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
