import axios from "axios";
import { Message } from "element-ui";
// axios.defaults.headers["Content-Type"] =
//   "application/x-www-form-urlencoded; charset=UTF-8";
// // 创建axios实例 "
const service = axios.create({
  baseURL: "https://manage.youqianxi.com/api/",
  timeout: 10000,
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["token"] = localStorage.getItem("token") || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (response.data.code == 0) {
      Message.error(response.data.msg);
    }
    if (response.data.code == -1) {
      window.location.href = "/login";
    }
    return response;
  },
  function (error) {
    if (error.response.data.code == 401) {
      // Message.error(error.response.data.msg);
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);
//get请求
const $get = (url, param) => {
  // param.token = localStorage.getItem("token");
  return new Promise((resolve) => {
    service({
      method: "get",
      url: url,
      params: param,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        // console.log(err, "异常");
      });
  });
};
//post请求
const $post = (url, param) => {
  return new Promise((resolve) => {
    service({
      method: "post",
      url: url,
      data: param,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        // console.log(err, "异常");
      });
  });
};

//upload上传
const $upload = (data) => {
  // 创建一个 FormData 对象来传递文件
  const formData = new FormData();
  formData.append("file", data.file); // 假设你的文件在 data 对象的 file 属性中
  return new Promise((resolve) => {
    service({
      method: "post",
      url: "/api/common/upload",
      data: formData,
      // 设置请求头，告诉服务器这是一个文件上传请求
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "异常");
      });
  });
};

export { service, $get, $post, $upload };
