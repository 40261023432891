<template>
  <div id="app">
    <navbar :site="site"></navbar>
    <div class="container">
      <router-view />
      <footerView :site="site"></footerView>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import footerView from "@/components/footerView.vue";
export default {
  components: { navbar, footerView },
  data() {
    return {
      site: [],
    };
  },
  mounted(){
    this.getSite()
  },
  methods:{
    getSite(){
      this.$post("index/site").then(
        (res) => {
          // console.log(res.data)
          this.site = res.data
        }
      );
    },
  }
};
</script>
<style lang="scss" scope>
body,
html {
  overflow-x: hidden;
  line-height: 1.6;
  font-size: 16px;
  background: #fafafa;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

ul li {
  list-style: none;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.imgDiv {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: all 0.3s;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  img:hover {
    transform: scale(1.05);
  }
}
.ovh {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ovh2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.pointer {
  cursor: pointer;
}
.main {
  min-height: calc(100vh - 260px);
}
.el-image {
  width: 100%;
  height: 100%;
}
.swiper-container {
  height: 100%;
}
.wrap {
  // padding: 0 13%;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
  // .wrap {
  //   padding: 0 5%;
  // }
}
@media screen and (max-width: 1024px) {
  .backtop {
    right: 23px;
  }
}
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}
</style>
